import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../layouts"
import '../styles/events.scss';
import { Helmet } from "react-helmet"
import { GatsbyImage } from "gatsby-plugin-image"
import '../styles/contact.scss';
import { BsFillTelephoneFill } from "react-icons/bs"
import { MdMail, MdLocationOn } from 'react-icons/md';
import Button from '../components/button/button';
import 'uikit/dist/css/uikit.css'

const Contact = () => {

  const [errMessage, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactMessage, setContactMessage] = useState({

    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleInput = async (event) => {

    event.persist();
    setContactMessage({ ...contactMessage, [event.target.name]: event.target.value })

  }

  const handleSubmit = (event) => {


    event.preventDefault();
    setLoading(true)
    var body = {

      FormData: JSON.stringify(contactMessage)
    };
    console.log(body);
    fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

      method: "POST",
      body: JSON.stringify(body),
      headers: {

        'Content-Type': 'application/json',
        'redirect': 'follow',
        'mode': 'no-cors',
      }
    }).then(response => response.text().then(function (responseText) {
      setLoading(false);
      if (response.status === 200) {
        setMessage("Thanks for your message!")
      }
      else {
        setMessage("Something gone wrong...Please try again later!")
      }
    }))
  }

  const data = useStaticQuery(graphql`
        {
            strapiContact {
              SEODescription
              SEOTitle
              footerDisclaimer
              Image{
                localFile{
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        gatsbyImageData(
                          width: 1900
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                }
              }
            }
            allFile {
              edges {
                node {
                  publicURL
                  id
                  name
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1900) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    gatsbyImageData(
                      width: 1900
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }


    `)

  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

        // imgUrl = item.node.publicURL
      }
      return ("")

    })
    // console.log(imgUrl)
    return (imgUrl)
  }

  return (

    <Layout SEOTitle={data.strapiContact.SEOTitle} SEODescription={data.strapiContact.SEODescription} footerDisclaimer={data.strapiContact.footerDisclaimer}>

      {/** List of meta properties coming from strapi that applies only htmlFor the homepage */
        <Helmet>
          {data.strapiContact.MetaTags?.map((item, index) => {
            return (
              <>

                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
              </>
            )
          })}
        </Helmet>

      }
      <GatsbyImage image={data.strapiContact.Image.localFile.childImageSharp.gatsbyImageData} alt="" className="contact-background-image"></GatsbyImage>
      <div className="contact-grid">
        <div className="uk-grid grid-container" uk-grid="true">
          <div className="uk-width-1-2 contact-column half-width">
            <div className="uk-card uk-card-default uk-card-body" id="contact-info">
              <ul>
                <div className="contact-column__location">
                  <div className="icon-location">
                    <MdLocationOn />
                  </div>
                  <div className="contact-column__location-content">
                    <p>Wayfarers Shopping Arcade <br />Lord Street <br />Southport <br /> PR8 1NT</p>
                  </div>
                </div>
                <div className="contact-column__location">
                  <div className="icon-location">
                    <BsFillTelephoneFill />
                  </div>
                  <div className="contact-column__location-content">
                    <p>Management Office <br /><a href="tel:+01704539077">01704 539077</a> <br />To find individual shops telephone<br /> numbers, <a href="#0" className="footer-click-link">click here</a></p>
                  </div>
                </div>
                <div className="contact-column__location">
                  <div className="icon-location">
                    <MdMail />
                  </div>
                  <div className="contact-column__location-content">
                    <p className="footer-email"><a href={`mailto:enquiries@wayfarersarcade.co.uk`}>enquiries@wayfarersarcade.co.uk</a></p>
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div className="uk-width-1-2 half-width">
            <div className="uk-card uk-card-default uk-card-body contact-second-column" id="contact-column-form">
              <form className="uk-grid-small uk-grid" uk-grid="true" onSubmit={handleSubmit}>

                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="form-stacked-text">Your name*</label>
                  <input className="uk-input" type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.name} name="name" />
                </div>

                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="form-stacked-text">Your contact number</label>
                  <input className="uk-input" type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.phone} name="phone" />
                </div>

                <div className="uk-width-1-1@s margin-top">
                  <label className="uk-form-label" htmlFor="form-stacked-text">Your email*</label>
                  <input className="uk-input" type="text" id="form-stacked-text" onChange={handleInput} value={contactMessage.email} name="email" />
                </div>

                <div className="uk-width-1-1@s margin-top">
                  <label className="uk-form-label" htmlFor="form-stacked-text">Your message</label>
                  <textarea className="uk-textarea" rows="6" onChange={handleInput} value={contactMessage.message} name="message" />
                </div>
                <div className="uk-width-1-1@s margin-top">
                  <div className="margin-top contact-send-btn">
                    <Button
                      id={"sign-up-submit"}
                      btnClass={"primary"}
                      btnType={"primary"}
                      label={`${loading == false ? 'Submit' : 'Loading...'}`}
                      isSubmit={"true"}
                      additionalClass={"full-width-btn"}
                    ></Button>
                  </div>
                </div>
                {errMessage ? <p>{errMessage}</p> : ""}
              </form>
            </div>
          </div>
        </div>
      </div>

    </Layout>


  )

}

export default Contact