import React from 'react';
import './button.scss';


const Button = ({isSubmit="false", btnType, id, label, clickAction, additionalClass="", isOutline="false"}) =>{
    return (
        <>
            <button id={id} type={isSubmit == "true" ? "submit" : "button"} className={`button button-${btnType == "secondary" && isOutline == "true" ? "secondary-outline" : btnType} ${additionalClass}`} onClick={clickAction}>{label}</button>
        </>
    )
}   

export default Button;